import classNames from 'classnames'
import React, { FunctionComponent } from 'react'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'

import * as styles from './Section5.module.scss'

const Section5: FunctionComponent = function () {
  return (
    <SectionWrapper
      className={classNames(
        styles.Section5,
        'd-flex flex-column justify-content-center',
      )}
    >
      {/* <p className="paragraph-lg text-gray-500 text-center mb-2">
        구름은 단순한 제품이 아닙니다
      </p> */}
      <h1 className="text-white text-center m-0">
        DX 시대, 최고의 성장 파트너
        <br />
        구름과 함께하세요
      </h1>
    </SectionWrapper>
  )
}

export default Section5

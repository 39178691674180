import React, { FunctionComponent, forwardRef, useEffect } from 'react'
import classNames from 'classnames'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'

import * as styles from './Section1.module.scss'
import ScrollHint from '_components/Common/ScrollHint/ScrollHint'
import Responsive from '_components/Common/Responsive/Responsive'

import STATIC_IMG_URL from '_images/Business/business-section1-mobile-image.png'
import STATIC_VIDEO_URL from '_images/Business/business-section1-video.mp4'

type Section1Props = {
  currentBreakpoint: string
}

const Section1: FunctionComponent<Section1Props> = function ({ currentBreakpoint }, ref) {
  const headRef = React.useRef<HTMLDivElement>(null)
  const paragraphRef = React.useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (headRef.current instanceof HTMLElement) {
      headRef.current.style.transition =
        'transform 1s ease-out, opacity 0.6s ease-out 0.4s'
      headRef.current.style.opacity = '1'
      headRef.current.style.transform = 'translateY(0px)'
    }
    if (paragraphRef.current instanceof HTMLElement) {
      paragraphRef.current.style.transition =
        'transform 1s ease-out 0.2s, opacity 0.4s ease-out 0.4s'
      paragraphRef.current.style.opacity = '1'
      paragraphRef.current.style.transform = 'translateY(0px)'
    }
  })

  return (
    <SectionWrapper
      ref={ref}
      className={classNames(
        styles.Section1,
        'd-flex flex-column justify-content-center text-center position-relative',
      )}
    >
      <video
        playsInline
        autoPlay
        muted
        loop
        src={currentBreakpoint !== 'xs' ? STATIC_VIDEO_URL : ''}
        poster={STATIC_IMG_URL}
        className={styles.Section1__video}
      />
      <h1
        className={classNames('text-white', styles.Section1__head)}
        ref={headRef}
      >
        DX 시대
        <br />
        사람과 고객 비즈니스의
        <br />
        성장을 돕습니다
      </h1>
      <Responsive
        def={
          <p
            className={classNames(
              'paragraph-lg text-gray-400',
              styles.Section1__paragraph,
            )}
            ref={paragraphRef}
          >
            구름은 이 시대가 당면한 디지털 트랜스포메이션을 돕기 위해
            <br />
            SW 교육과 평가의 어려움, 개발 프로세스의 복잡함을
            <br />
            최고의 기술과 서비스, 노하우를 통해 해결합니다.
          </p>
        }
        sm={
          <p
            className={classNames(
              'paragraph-lg text-gray-400',
              styles.Section1__paragraph,
            )}
            ref={paragraphRef}
          >
            구름은 이 시대가 당면한 디지털 트랜스포메이션을
            <br />
            SW 교육과 평가의 어려움, 개발 프로세스의 <br />
            복잡함을 최고의 기술과 서비스, 노하우를 통해 해결합니다.
          </p>
        }
      />
      <ScrollHint />
    </SectionWrapper>
  )
}

export default forwardRef(Section1)

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import Header from '_components/Common/Header/Header'
import PageWrapper from '_components/Common/PageWrapper/PageWrapper'
import Section1 from '_containers/Business/Section1/Section1'
import Section2 from '_containers/Business/Section2/Section2'
import Section3 from '_containers/Business/Section3/Section3'
import Section4 from '_containers/Business/Section4/Section4'
import Section5 from '_containers/Business/Section5/Section5'

import useGridBreakpoint from '_hooks/useGridBreakpoint'

const Business: FunctionComponent = function () {
  const sectionsRef = useRef<HTMLDivElement[]>(Array.from({ length: 5 }))
  const [headerColor, setHeaderColor] = useState<string>('dark')
  const currentBreakpoint: string = useGridBreakpoint([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ])
  
  useEffect(() => {
    let animationThrottling = false

    const onScroll = () => {
      if (animationThrottling) return
      animationThrottling = true

      const scrollY = window.pageYOffset
      const section1Height = sectionsRef.current[0]?.clientHeight

      requestAnimationFrame(() => {
        animationThrottling = false
      })

      // Section1 에 있음
      const sum = section1Height
      if (scrollY < sum) {
        setHeaderColor('dark')
        return
      }
      setHeaderColor('light')
    }

    document.addEventListener('scroll', onScroll, {
      passive: true,
    })
  }, [])

  return (
    <PageWrapper Header={<Header color={headerColor} />}>
      <Section1
        ref={ref => {
          sectionsRef.current[0] = ref
        }}
        currentBreakpoint={currentBreakpoint}
      />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </PageWrapper>
  )
}

export default Business

import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Badge, Card, CardBody } from '@goorm-dev/components'

import * as styles from './ContentsBoxMobile.module.scss'
import SplitText from '_components/Common/SplitText/SplitText'

type ContentsBoxMobileProps = {
  src: string
  badge: string
  badgeColor?: string
  title: string
  desc: string
}

const ContentsBoxMobile: FunctionComponent<ContentsBoxMobileProps> = function ({
  src = '',
  badge = '',
  badgeColor = 'warning',
  title = '',
  desc = '',
}) {
  return (
    <div className={classNames(styles.ContentsBoxMobile, 'bg-gray-50')}>
      <Card className="bg-gray-50">
        <header className={styles.ContentsBoxMobile__header}>
          <img src={src} alt="goormee card" />
        </header>
        <CardBody
          className={classNames(styles.ContentsBoxMobile__body, 'text-left')}
        >
          <Badge size="md" color={badgeColor} className="mb-3">
            {badge}
          </Badge>
          <h3 className="mb-2">
            <SplitText text={title} />
          </h3>
          <p className="paragraph text-left text-gray-600">{desc}</p>
        </CardBody>
      </Card>
    </div>
  )
}

export default ContentsBoxMobile

import React, { FunctionComponent } from 'react'

import ContentsWrapper from '_components/Business/ContentsWrapper/ContentsWrapper'

import { CONTNENTS_LIST_2 } from '_constants/businessContentsList'

const Section4: FunctionComponent = function () {
  return (
    <ContentsWrapper
      title="Platform for Business"
      subTitle="맞춤형 솔루션을 제안합니다."
      list={CONTNENTS_LIST_2}
      rowReverse={true}
      badgeColor="success"
    />
  )
}

export default Section4

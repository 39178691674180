import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'

import useInterval from '_hooks/useInterval'
import Bubble from '_images/Business/assets/business-section2-asset.svg'

import * as styles from './Section2.module.scss'

const ITEM_WIDTH = 360 / 16
const ITEM_SPACE = 32 / 16

const QUESTION_LIST = [
  {
    id: '0',
    desc: (
      <h3>
        우리 회사에 맞춘
        <br />
        <span className="text-blue-400">교육 커리큘럼</span>은<br />
        없을까?
      </h3>
    ),
  },
  {
    id: '1',
    desc: (
      <h3>
        <span className="text-blue-400">코딩 실습</span>이 가능하면서
        <br />
        <span className="text-blue-400">자기주도학습</span>에 효과적인
        <br />
        LMS는 없을까?
      </h3>
    ),
  },
  {
    id: '2',
    desc: (
      <h3>
        <span className="text-blue-400">적은 HR 리소스로</span>
        <br />
        코딩 테스트를 진행하려면
        <br />
        어떻게 해야 할까?
      </h3>
    ),
  },
  {
    id: '3',
    desc: (
      <h3>
        <span className="text-blue-400">사내 DX 교육</span>을<br />
        활성화하려면
        <br />
        무엇을 해야 할까?
      </h3>
    ),
  },
  {
    id: '4',
    desc: (
      <h3>
        데이터 리터러시, AI 등<br />
        요즘 <span className="text-blue-400">트렌디한 강의</span>가<br />
        없을까?
      </h3>
    ),
  },
  {
    id: '5',
    desc: (
      <h3>
        MZ 세대에 적합한
        <br />
        <span className="text-blue-400">SW 교육 방식</span>은<br />
        무엇일까?
      </h3>
    ),
  },
  {
    id: '6',
    desc: (
      <h3>
        <span className="text-blue-400">Zoom 대신 쓸만한</span>
        <br />
        화상 강의 솔루션은
        <br />
        없을까?
      </h3>
    ),
  },
]
const SLIDE_DELAY = 2500

const Section2: FunctionComponent = function () {
  const list = QUESTION_LIST.concat(QUESTION_LIST, QUESTION_LIST)
  const len = list.length
  const breakPoint = Math.floor(len / 3)

  const [index, setIndex] = useState<number>(0)
  const animationStyle = {
    transitionDuration: '0.8s',
  }
  const noneAnimationStyle = {
    transitionDuration: '0s',
  }
  const [animationDuration, setAnimationDuration] = useState(animationStyle)

  useInterval(() => {
    if (index === breakPoint) {
      setAnimationDuration(noneAnimationStyle)
      setIndex(0)
    } else {
      setAnimationDuration(animationStyle)
      setIndex(index + 1)
    }
  }, SLIDE_DELAY)

  return (
    <section
      className={classNames(
        styles.Section2,
        'd-flex flex-column align-items-center',
      )}
    >
      <h1 className="text-center">
        혹시, 이런 고민 해보신 적 <br className="d-md-none d-block" />
        있으신가요?
      </h1>
      <div
        className={classNames(
          'd-flex justify-content-center',
          styles.Section2__container,
        )}
      >
        <ul
          style={{
            transform: `translateX(${index * (ITEM_WIDTH + ITEM_SPACE)}rem)`,
            ...animationDuration,
          }}
          className={styles.Section2__wrapper}
        >
          {list.map((item, idx) => (
            <li
              key={idx}
              style={{ width: `${ITEM_WIDTH}rem` }}
              className={classNames(styles.Section2__box, 'text-center')}
            >
              {item.desc}
            </li>
          ))}
        </ul>
      </div>
      <Bubble className={styles.Section2__bubble} />

      <div
        className={classNames(
          'h-100 d-xxl-block d-none',
          styles.Section2__tint,
          styles.Section2__tintLeft,
        )}
      />
      <div
        className={classNames(
          'h-100 d-xxl-block d-none',
          styles.Section2__tint,
          styles.Section2__tintRight,
        )}
      />
    </section>
  )
}

export default Section2

import React, { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

const constatns = {
  size: {
    xs: 375,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
}

type ResponsiveComponentProps = {
  // MEMO: 잘못된 설계로 인해 한 치수 높게 입력해야 합니다. ex: xs -> sm
  xs?: React.FC | JSX.Element
  sm?: React.FC | JSX.Element
  md?: React.FC | JSX.Element
  lg?: React.FC | JSX.Element
  xl?: React.FC | JSX.Element
  xxl?: React.FC | JSX.Element
  def: React.FC | JSX.Element
}

const Responsive: React.FC<ResponsiveComponentProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  def,
}) => {
  const DEBOUNCE_MS = 200
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const handleResize = debounce(
    () => setWindowWidth(window.document.body.clientWidth),
    DEBOUNCE_MS,
  )

  useEffect(() => {
    setWindowWidth(window.document.body.clientWidth)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { size } = constatns
  if (xs && windowWidth < size.xs) {
    return <>{xs}</>
  } else if (sm && windowWidth < size.sm) {
    return <>{sm}</>
  } else if (md && windowWidth < size.md) {
    return <>{md}</>
  } else if (lg && windowWidth < size.lg) {
    return <>{lg}</>
  } else if (xl && windowWidth < size.xl) {
    return <>{xl}</>
  } else if (xxl && windowWidth < size.xxl) {
    return <>{xxl}</>
  }

  return <>{def}</>
}

export default Responsive

import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import Arrow from '_images/Main/assets/main-section1-arrow.svg'

import * as styles from './ScrollHint.module.scss'

type ScrollHintProps = {
  className?: string
}

const ScrollHint: FunctionComponent<ScrollHintProps> = function ({
  className,
}) {
  return <Arrow className={classNames(styles.ScrollHint, className)} />
}

export default ScrollHint

import React, { FunctionComponent, ReactNode } from 'react'
import classNames from 'classnames'

import * as styles from './SectionHeader.module.scss'

type SectionHeaderProps = {
  children: ReactNode
  className?: string
}

const SectionHeader: FunctionComponent<SectionHeaderProps> = function ({
  children,
  className,
}) {
  return (
    <header className={classNames(styles.SectionHeader, className)}>
      {children}
    </header>
  )
}

export default SectionHeader

import React, { FunctionComponent } from 'react'

import ContentsWrapper from '_components/Business/ContentsWrapper/ContentsWrapper'

import { CONTNENTS_LIST } from '_constants/businessContentsList'

const Section3: FunctionComponent = function () {
  return (
    <ContentsWrapper
      title="EDU for Business"
      subTitle="새로운 교육을 제안합니다."
      list={CONTNENTS_LIST}
    />
  )
}

export default Section3

import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Row, Col } from '@goorm-dev/components'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import ContentsBox from '../ContentsBox/ContentsBox'
import ContentsBoxMobile from '../ContentsBoxMobile/ContentsBoxMobile'

import * as styles from './ContentsWrapper.module.scss'

type ContentsWrapperProps = {
  title: string
  subTitle: string
  list: {
    id: string
    badge: string
    src: string
    title: string
    desc: string
  }[]
  rowReverse?: boolean
  badgeColor?: string
}

const ContentsWrapper: FunctionComponent<ContentsWrapperProps> = function ({
  title,
  subTitle,
  list = [],
  rowReverse = false,
  badgeColor = 'warning',
}) {
  return (
    <SectionWrapper className={classNames(styles.ContentsWrapper, 'bg-white')}>
      <SectionHeader className="d-flex flex-column align-items-center">
        <h6 className="text-gray-600 mb-3">{title}</h6>
        <h1>{subTitle}</h1>
      </SectionHeader>
      <article className="d-md-block d-none">
        {list.map((item, idx) => (
          <Row
            key={item.id}
            className={classNames(styles.ContentsWrapper__row, {
              'd-flex flex-row-reverse': rowReverse
                ? idx % 2 === 0
                : idx % 2 === 1,
            })}
          >
            <Col>
              <ContentsBox type="image" src={item.src} />
            </Col>
            <Col>
              <ContentsBox
                type="text"
                badge={item.badge}
                badgeColor={badgeColor}
                title={item.title}
                desc={item.desc}
              />
            </Col>
          </Row>
        ))}
      </article>
      <article className="d-md-none d-flex flex-column align-items-center">
        {list.map(item => (
          <ContentsBoxMobile
            key={item.id}
            src={item.src}
            badge={item.badge}
            badgeColor={badgeColor}
            title={item.title}
            desc={item.desc}
          />
        ))}
      </article>
    </SectionWrapper>
  )
}

export default ContentsWrapper

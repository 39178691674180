import React, { FunctionComponent } from 'react'
import { Badge } from '@goorm-dev/components'

import * as styles from './ContentsBox.module.scss'
import classNames from 'classnames'
import SplitText from '_components/Common/SplitText/SplitText'

type ContentsBoxProps = {
  type: string
  src?: string
  badge?: string
  badgeColor?: string
  title?: string
  desc?: string
}

const ContentsBox: FunctionComponent<ContentsBoxProps> = function ({
  type,
  src = '',
  badge = '',
  badgeColor = 'warning',
  title = '',
  desc = '',
}) {
  if (type === 'image')
    return (
      <div className={styles.ContentsBox__wrapper}>
        <img src={src} />
      </div>
    )
  return (
    <div
      className={classNames(
        styles.ContentsBox__wrapper,
        styles.ContentsBox__textWrapper,
        'bg-gray-50',
      )}
    >
      <Badge
        size="lg"
        color={badgeColor}
        className="mb-3 d-xl-inline-flex d-none"
      >
        {badge}
      </Badge>
      <Badge
        size="md"
        color={badgeColor}
        className="mb-3 d-xl-none d-inline-flex"
      >
        {badge}
      </Badge>
      <h2 className={classNames(styles.ContentsBox__header, 'mb-3')}>
        <SplitText text={title} />
      </h2>
      <p className="paragraph text-gray-600">{desc}</p>
    </div>
  )
}

export default ContentsBox

import { useState, useEffect } from 'react'
import { getMediaQueryBreakpoint } from '_utils/utils'

const useGridBreakpoint = (customBreakpoints, defaultBreakpoint) => {
  const [breakpoint, setBreakpoint] = useState(defaultBreakpoint)

  useEffect(() => {
    const { mediaQueryBreakpointList, getCurrentBreakpoint } =
      getMediaQueryBreakpoint(customBreakpoints)
    setBreakpoint(getCurrentBreakpoint())

    const onMatchMediaQuery = () => setBreakpoint(getCurrentBreakpoint())
    mediaQueryBreakpointList.forEach(bpmq =>
      bpmq.mediaQuery.addListener(onMatchMediaQuery),
    )
    return () => {
      mediaQueryBreakpointList.forEach(bpmq =>
        bpmq.mediaQuery.removeListener(onMatchMediaQuery),
      )
    }
  }, [])

  return breakpoint
}
export default useGridBreakpoint

export const CONTNENTS_LIST = [
  {
    id: '0',
    badge: '교육 콘텐츠 제작',
    src: '/assets/business-section3-image1.png',
    title: '성장으로 이어지는\n실무 중심 교육 콘텐츠',
    desc: '훈련생이 성장하기 위해서는 기술 변화에 발맞춰 교육 콘텐츠도 지속적으로 변화해야 합니다. 구름은 최신 기술 동향에 발맞춰 실무 중심의 살아있는 교육 콘텐츠를 만듭니다.',
  },
  {
    id: '1',
    badge: '온·오프라인 교육 프로그램 설계',
    src: '/assets/business-section3-image2.png',
    title: '부트캠프부터 해커톤,\n멘토링, 컨퍼런스까지',
    desc: '구름EDU 플랫폼 기반의 온∙오프라인 교육은 물론 비즈니스 니즈에 맞춰 부트캠프, 프로젝트, 해커톤, 멘토링, 컨퍼런스 등 다양한 형태의 교육 프로그램을 설계하고 운영합니다.',
  },
  {
    id: '2',
    badge: '교육 사업  A to Z',
    src: '/assets/business-section3-image3.png',
    title: '모집부터\n교육, 취업 연계까지 ',
    desc: '고용노동부, 정보통신산업진흥원, 정보통신기획평가원 등 디지털 교육에 관심 있는 정부 기관을 비롯해 다양한 기업과 제휴하여 모집부터, 선발, 교육, 수료, 취업 연계까지 교육생의 성장 여정을 함께합니다.',
  },
]

export const CONTNENTS_LIST_2 = [
  {
    id: '0',
    badge: '올인원 SW 교육 플랫폼',
    src: '/assets/business-section4-image1.png',
    title: '교육 성과는 높이고\n관리 시간은 줄이고',
    desc: 'SW 교육, 일반 직무 교육, 법정 의무 교육 등 목적에 맞춰 다양한 포맷의 콘텐츠를 제작하고 이용할 수 있습니다. 필요한 관리 기능을 선택하여 우리 회사 맞춤형 교육 플랫폼을 구성해 보세요. 학습, 소통, 라이브 강의, 자료 공유까지 다양한 교육 방식을 적용할 수 있습니다.',
  },
  {
    id: '1',
    badge: 'SW 역량 평가 플랫폼',
    src: '/assets/business-section4-image2.png',
    title: '적은 HR 리소스로\n효율적인 인재 검증 ',
    desc: '문제 출제부터 전용 채널 개설, 초대, 감독, 결과 확인까지 공정하고 간편한 프로세스를 경험해 보세요. 응시 인원 규모에 상관없이 안정적인 서비스 운영이 가능하며, 코딩 테스트뿐 아니라 데이터 분석 시험, 교육 성취도 평가, 직무 역량 평가 및 공인/민간 자격시험에도 활용할 수 있습니다.',
  },
  {
    id: '2',
    badge: 'SW 개발 도구',
    src: '/assets/business-section4-image3.png',
    title: '빠르고 유연한\n클라우드 개발 환경',
    desc: '복잡한 설치 과정 없이 몇 번의 클릭만으로 빠르고 유연한 개발 환경을 구축할 수 있습니다. 웹 서비스부터 GUI 애플리케이션 개발, 구름EDU-구름IDE 연동을 통한 SW 프로젝트 교육까지. 비즈니스 목적에 맞춰 다양하게 활용할 수 있습니다.',
  },
]
